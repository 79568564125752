import { useState } from "react";
import { generateRandomImage, randomizeImage } from "./generate-random-image";
import { Image, ImageProps } from "./image";

export function App() {
  const [baseImage, setBaseImage] = useState<ImageProps>();

  return (
    <div
      style={{
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <header
        style={{
          fontSize: 24,
          padding: 24,
          letterSpacing: 4,
          position: "sticky",
          top: 0,
          background: "white",
        }}
      >
        <h1>
          {baseImage ? (
            <button onClick={() => setBaseImage(undefined)}>Back</button>
          ) : (
            "Ethereal"
          )}
        </h1>
      </header>
      <div
        style={{
          minHeight: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 20,
          paddingTop: 0,
        }}
      >
        <div>
          {baseImage
            ? (() => {
                const images: ImageProps[] = [baseImage];
                for (let i = 0; i < 23; i++) {
                  const prevImage = images[images.length - 1];
                  images.push(
                    randomizeImage(prevImage.id + "/" + i, prevImage)
                  );
                }
                return images.map((image) => (
                  <div
                    style={{
                      display: "inline-block",
                      padding: 20,
                      width: 250,
                    }}
                  >
                    <Image {...image} />
                  </div>
                ));
              })()
            : new Array(12).fill(0).map((_, i) => {
                const image = generateRandomImage(String(i));
                return (
                  <div
                    style={{ display: "inline-block", padding: 20, width: 250 }}
                    onClick={() => setBaseImage(image)}
                  >
                    <Image {...image} />
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
