type HSLA = { h: number; s: number; l: number; a: number };

function hsla(color: HSLA): string {
  return `hsla(${color.h},${color.s}%,${color.l}%,${color.a})`;
}

function rotate(color: HSLA, degree: number): HSLA {
  return { ...color, h: (color.h + degree) % 360 };
}

function lighten(color: HSLA, amount: number): HSLA {
  return { ...color, l: bound(color.l + amount, 0, 100) };
}

function bound(value: number, min: number, max: number): number {
  return Math.max(Math.min(value, max), min);
}

export type ImageProps = {
  id: string;
  hue: number;
  chaos: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
};

export function Image({ id, hue, chaos }: ImageProps) {
  const color = { h: hue, s: 66, l: 83, a: 1 };
  let fg = { ...color, l: 78, a: 0.6 };
  let bg = color;
  let stroke = { h: 0, s: 0, l: 0, a: 1 };

  // Dark
  if (chaos[4] > 8) {
    fg = { ...fg, s: 40, a: 1 };
    bg = { ...bg, l: 10 };
  }
  // Albino
  if (chaos[6] > 8) {
    stroke = { ...stroke, l: 100 };
    fg = { ...fg, a: 0.4, l: 90 };
    bg = { ...bg, s: 65, l: 60 };
  }
  // Signature
  if (chaos[8] === 8) {
    fg = { ...fg, a: 1 };
  }

  const isE = chaos[6] < 1 && chaos[7] < 1;

  const octo = (
    <g>
      <path
        d="M135 233.587L224.578 87V276.244L135 233.587Z"
        fill={`url(#face1-${id})`}
      />
      <path
        d="M224.578 201.169L135 233.587L224.578 276.244V201.169Z"
        fill={hsla(fg)}
      />
      <path
        d="M135 248.091L224.578 290.747V363.5L135 248.091Z"
        fill={hsla(lighten(fg, chaos[3] * 1.5 - 10))}
      />
      <g
        style={
          chaos[8] === 7 && chaos[9] > 8
            ? { transform: `translate(50px)` }
            : undefined
        }
      >
        <path
          d="M314.156 233.587L224.578 87V276.244L314.156 233.587Z"
          fill={`url(#face2-${id})`}
        />
        <path
          d="M224.578 201.169L314.156 233.587L224.578 276.244V201.169Z"
          fill={hsla(rotate(fg, -(chaos[2] * 90 + 90)))}
        />
        <path
          d="M314.156 248.091L224.578 290.747V363.5L314.156 248.091Z"
          fill={hsla(
            lighten(rotate(fg, -(chaos[2] * 90 + 90)), chaos[3] * 1.5 - 5)
          )}
        />
      </g>
    </g>
  );

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 450 450"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="450" height="450" fill={`url(#bg-${id})`} />

      <g
        stroke={hsla({ ...stroke, l: bg.l < 50 ? 50 : stroke.l })}
        stroke-linejoin="round"
      >
        {chaos[8] > 8 ? (
          <rect x="135" y="87" width="179.156" height="276.5" />
        ) : chaos[8] > 7 ? (
          <>
            {new Array(29).fill(0).map((_, i) => (
              <line
                key={i}
                x1="15"
                x2="15"
                y1={450 - chaos[i % 10] * 40 - 30}
                y2="450"
                style={{
                  transform: `translate(${i * 15}px, 0)`,
                }}
              />
            ))}
          </>
        ) : chaos[8] > 6 ? (
          <line x1="225" x2="225" y1="0" y2="450" />
        ) : null}

        {!chaos[3] ? (
          <>
            <g
              style={{
                transform:
                  chaos[4] < 5
                    ? "translate(30px, 0)"
                    : "translate(-50px, -25px)",
              }}
            >
              {octo}
            </g>
            <g
              style={{
                transform:
                  chaos[4] < 5
                    ? "translate(-30px, 0)"
                    : "translate(202px, 218px) scale(0.5)",
              }}
            >
              {octo}
            </g>
          </>
        ) : (
          octo
        )}
      </g>

      {isE && (
        <g fill={hsla({ ...fg, l: 100 - bg.l })}>
          <rect x="136" y="119" width="177.156" height="24" />
          <rect x="148.01" y="212" width="152.559" height="24" />
          <rect x="136" y="305" width="177.156" height="24" />
        </g>
      )}

      <defs>
        <radialGradient
          id={`bg-${id}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-2.51116 4.5201) rotate(45) scale(730.861)"
        >
          <stop stop-color={hsla(rotate(bg, -45))} />
          <stop offset="1" stop-color={hsla(rotate(bg, 45))} />
        </radialGradient>
        <linearGradient
          id={`face1-${id}`}
          x1="179.789"
          y1="100.5"
          x2="179.789"
          y2="276.244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={hsla(rotate(fg, chaos[0] * 25 + 20))} />
          <stop
            offset="1"
            stop-color={hsla(rotate(fg, chaos[0] * 25 + 40))}
            stop-opacity="0"
          />
        </linearGradient>
        <linearGradient
          id={`face2-${id}`}
          x1="269.367"
          y1="100.5"
          x2="269.367"
          y2="276.244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={hsla(rotate(fg, -(chaos[1] * 90 + 90)))} />
          <stop
            offset="1"
            stop-color={hsla(rotate(fg, -(chaos[1] * 90 + 120)))}
            stop-opacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
