import { ImageProps } from "./image";

function rand(max: number): number {
  return Math.floor(Math.random() * max);
}

export function generateRandomImage(id: string): ImageProps {
  return {
    id,
    hue: rand(360),
    chaos: [
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
    ],
  };
}

export function randomizeImage(id: string, image: ImageProps): ImageProps {
  return {
    id,
    hue: image.hue,
    chaos: [
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
      rand(10),
    ],
  };
}
